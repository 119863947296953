.sd-product-grid {
  .elc-orderable-filters-wrapper {
    width: 100%;
    margin-bottom: 15px;
    @include breakpoint($portrait-up) {
      width: auto;
    }
    .elc-collapsible-wrapper {
      position: absolute;
      width: 100%;
      background: $color-white;
      z-index: 2;
      margin: 0;
      padding: 0 22px 50px;
      #{$ldirection}: 0;
      .elc-overlay-wrapper-close-button {
        width: auto;
        height: auto;
        float: $rdirection;
        top: 20px;
        padding: 0;
        background: none;
        border: none;
      }
    }
  }
}

.elc-orderable-filters-wrapper,
.elc-mobile-filters-modal {
  .elc-product-grid {
    &--filter-set-wrapper {
      padding: 15px;
      border-top: 1px solid $color-sbx-dgrey;
      @include breakpoint($medium-up) {
        padding: 0;
        border-top: none;
        &:first-of-type {
          margin-top: 20px;
        }
        label {
          @include NeueHaasGrotesk-medium;
          font-size: 20px;
          display: inline-block;
          width: 200px;
          vertical-align: middle;
          margin-#{$rdirection}: 10px;
          letter-spacing: 0;
        }
      }
    }
    &--filter-set {
      @include NeueHaasGrotesk-bold;
      color: $color-black;
      text-transform: uppercase;
      padding: 0 5px;
      &.elc-button {
        background: none;
      }
      &:after {
        background: url('#{$base-theme-path}img/icons/src/plus.svg') no-repeat center;
        #{$rdirection}: 25px;
        content: '';
        display: inline-block;
        height: 16px;
        position: absolute;
        width: 16px;
      }
      &.active {
        &:after {
          background: url('#{$base-theme-path}img/icons/src/minus.svg') no-repeat center;
        }
      }
    }
    &--filter-options-wrapper {
      display: inline-flex;
      flex-wrap: wrap;
      margin-top: 20px;
      @include breakpoint($medium-up) {
        margin-top: 0;
        width: calc(100% - 210px);
      }
    }
    &--filter-option {
      @include NeueHaasGrotesk-regular;
      margin: 5px;
      flex: 0 1 calc(50% - 10px);
      max-width: 200px;
      background-color: $color-white;
      color: $color-black;
      border: 1px solid $color-black;
      border-radius: 9px;
      text-transform: capitalize;
      &.button--light {
        background-color: $color-white;
        color: $color-black;
        border-color: $color-black;
      }
      &.button--dark {
        background-color: $color-sbx-red;
        color: $color-white;
        border-color: $color-sbx-red;
      }
      &:disabled,
      &.no-results {
        background: $color-silver;
        border-color: $color-silver;
        color: $color-sbx-dgrey;
      }
      @include breakpoint($medium-up) {
        margin: 15px;
        flex: 0 1 48%;
        font-size: 17px;
        line-height: 20px;
      }
    }
    &--clear-all {
      @include NeueHaasGrotesk-medium;
      float: $rdirection;
      color: $color-black;
      font-size: 14px;
      letter-spacing: 0;
      border-bottom: 2px solid $color-black;
      border-radius: 0;
      padding: 0;
      margin: 20px;
      &.elc-button {
        background: none;
      }
      .elc-remove-icon {
        display: none;
      }
      @include breakpoint($medium-up) {
        @include NeueHaasGrotesk-bold;
        font-size: 17px;
        margin: 20px 0 0;
      }
    }
    &--filters-section {
      display: flex;
      @include breakpoint($portrait-up) {
        display: block;
      }
    }
    &--filters-modal-button {
      @include NeueHaasGrotesk-regular;
      min-width: 145px;
      width: auto;
      padding: 10px 14px;
      text-align: $ldirection;
      line-height: 24px;
      letter-spacing: normal;
      border-radius: 9px;
      @include breakpoint($portrait-up) {
        min-width: 200px;
      }
      .elc-applied-filters-number {
        margin-#{$ldirection}: 3px;
      }
      .elc-filter-icon {
        float: $rdirection;
      }
    }
    &--filter-results-count-wrapper {
      width: auto;
      padding: 14px;
      color: $color-sbx-dgrey;
      @include breakpoint($portrait-up) {
        padding: 10px 5px;
      }
    }
    &--apply-filter {
      position: fixed;
      bottom: 0;
      background: $color-white;
      border-radius: 0;
      width: 100%;
      padding: 20px;
      border-top: 1px solid $color-sbx-dgrey;
    }
    &--apply-filter-label {
      @include NeueHaasGrotesk-bold;
      font-size: 14px;
      color: $color-white;
      background-color: $color-black;
      padding: 10px 20px;
      border-radius: 9px;
    }
  }
}

.elc-responsive-modal-mask {
  .elc-mobile-filters-modal {
    #{$ldirection}: 0;
    width: 100%;
    min-height: 100%;
    min-width: 310px;
    padding: 60px 0 90px;
    top: 0;
    transform: none;
    .elc-overlay-wrapper-close-button {
      width: auto;
      height: auto;
      position: fixed;
      top: 0;
      #{$rdirection}: 20px;
      padding: 0;
      margin: 20px 0;
      background: none;
      border: none;
      z-index: 2;
    }
    .elc-filter-by-label {
      @include NeueHaasGrotesk-bold;
      padding: 20px;
      line-height: 20px;
      font-size: 14px;
      text-transform: uppercase;
      position: fixed;
      top: 0;
      width: 100%;
      #{$ldirection}: 0;
      background: $color-white;
      border-bottom: 1px solid $color-sbx-dgrey;
      z-index: 1;
    }
  }
}
