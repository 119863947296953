@import '../../../../scss/theme-bootstrap';
@import '../../../../scss/components/_mpp_filters';

.sd-product-grid {
  font-family: $NeueHaasGrotesk;
  min-height: 500px;
  max-width: 1310px;
  @include breakpoint($landscape-up) {
    margin: 20px auto;
  }
  .elc-product-grid-header {
    @include NeueHaasGrotesk-black;
    font-size: 26px;
    line-height: 1.5;
    margin-#{$ldirection}: 10px;
    @include breakpoint($landscape-up) {
      font-size: 44px;
      line-height: 1.7;
    }
  }
  .elc-product-grid-header-wrapper {
    align-items: flex-start;
  }
  .elc-product-carousel-slider-view {
    .elc-slider-view-wrapper {
      > .slick-slider {
        @include swap_direction(padding, 0 30% 0 0);
        @include breakpoint($landscape-up) {
          padding: 0;
        }
        .slick-slider {
          padding: 0;
        }
        .slick-list {
          overflow: visible;
          @include breakpoint($landscape-up) {
            overflow: hidden;
          }
          .slick-list {
            overflow: hidden;
          }
        }
      }
    }
    .elc-slider-arrow-wrapper {
      &.slick-arrow {
        top: 25%;
        z-index: 1;
        @include breakpoint($landscape-up) {
          top: 50%;
        }
      }
      &.slick-prev {
        #{$ldirection}: 2px;
        @include breakpoint($landscape-up) {
          #{$ldirection}: -41px;
        }
      }
      &.slick-next {
        #{$rdirection}: -10px;
      }
      .elc-directional-icon {
        -webkit-mask-image: none;
        background-color: $color-black-55opacity;
        cursor: pointer;
        height: 50px;
        mask-image: none;
        padding: 5px 7px;
        transform: none;
        width: 45px;
        z-index: 1;
        @include breakpoint($landscape-up) {
          background-color: transparent;
        }
        &.elc-slider-arrow-left {
          @include icon('caret--left', before);
          font-size: 40px;
          font-style: normal;
          color: $color-white;
          @include breakpoint($landscape-up) {
            color: $color-black;
          }
        }
        &.elc-slider-arrow-right {
          @include icon('caret--right', before);
          font-size: 40px;
          font-style: normal;
          padding-#{$ldirection}: 0;
          color: $color-white;
          @include breakpoint($landscape-up) {
            color: $color-black;
          }
        }
      }
    }
    .slick-list {
      .elc-slider-arrow-wrapper {
        &.slick-arrow {
          @include breakpoint($landscape-up) {
            top: 10px;
          }
        }
        &.slick-prev {
          #{$ldirection}: -3px;
        }
        &.slick-next {
          #{$rdirection}: 11px;
        }
        .elc-directional-icon {
          width: 16px;
          height: 16px;
          background-color: transparent;
          &.elc-slider-arrow-left,
          &.elc-slider-arrow-right {
            color: $color-white;
            font-size: 15px;
            padding: 0 7px;
          }
        }
      }
    }
  }
  .elc-sort-wrapper {
    margin-bottom: 20px;
    @include breakpoint($portrait-up) {
      margin-#{$ldirection}: auto;
    }
    @include breakpoint($landscape-up) {
      margin-bottom: 30px;
    }
    .elc-dropdown {
      background: none;
      min-width: 200px;
      &-wrapper,
      &-arrow {
        border: none;
        &:hover {
          background: none;
        }
      }
      &-options-list {
        border: 1px solid $color-black;
      }
      &-readonly-input {
        color: $color-black;
        font-family: $NeueHaasGrotesk;
        font-size: 14px;
        justify-content: flex-start;
        text-transform: uppercase;
      }
    }
    select,
    .elc-dropdown-option {
      @include NeueHaasGrotesk-regular;
      color: $color-black;
      font-size: 14px;
      line-height: 22px;
      padding: 5px 10px;
      text-transform: uppercase;
    }
    .elc-dropdown-option {
      padding: 5px 10px;
    }
    .elc-dropdown,
    select {
      appearance: auto;
      background-color: $color-white;
      border-radius: 9px;
      border: 1px solid $color-black;
      width: 145px;
    }
  }
  .elc-grid-container {
    margin: 0 0 40px;
    @include breakpoint($landscape-up) {
      margin: 0 10px;
    }
    .elc-product-grid {
      &-header-wrapper {
        @include NeueHaasGrotesk-black;
        font-size: 26px;
        line-height: 1.5;
        @include breakpoint($landscape-up) {
          font-size: 44px;
          line-height: 1.7;
        }
      }
      &--header {
        box-shadow: none;
        justify-content: normal;
        padding: 20px 20px 0;
        @include breakpoint($landscape-up) {
          padding: 0 12px;
        }
      }
    }
    .elc-grid-item-tout,
    .elc-grid-item-product {
      padding: 0 20px 30px;
      position: relative;
      @include breakpoint($landscape-up) {
        margin-bottom: 50px;
        padding: 0 12px 60px;
      }
    }
    .elc-product-carousel-slider-view,
    .elc-grid-item-product {
      .elc-product-brief {
        padding-bottom: 60px;
        .elc-main-content-section,
        .elc-shade-swatches-wrapper {
          @include breakpoint($landscape-up) {
            width: auto;
          }
        }
        .elc-main-content-section {
          .elc-product-image-badge {
            @include product-image-badge(0.5, 0.4, 0.5);
            bottom: 420px;
            @include breakpoint($portrait-up) {
              top: 180px;
            }
            @include breakpoint($landscape-up) {
              top: calc(350px - 10%);
            }
          }
        }
        .elc-shade-swatches-wrapper {
          & + .elc-product-display-name-wrapper {
            padding: 0;
          }
          & ~ .elc-product-prices-wrapper[role='link'],
          & ~ .elc-product-prices-clickable-wrapper[role='link'] {
            margin-top: 12px;
          }
        }
        .elc-product-images-wrapper {
          @include breakpoint($landscape-up) {
            height: auto;
            min-height: auto;
            min-width: auto;
            width: auto;
          }
        }
        .elc-product-display-name-wrapper {
          margin: 0;
          padding-top: 37px;
        }
        .elc-product-prices-wrapper,
        .elc-product-prices-clickable-wrapper {
          &[role='link'] {
            margin-top: 12px;
          }
          .elc-price-formatted-wrapper {
            .elc-product-price {
              margin-top: 3px;
            }
          }
        }
        .elc-product-shade-picker {
          .slick-track {
            padding: 0;
            .slick-slide {
              margin: 0;
            }
          }
        }
      }
    }
    .elc-grid-item-tout {
      @include breakpoint($landscape-up) {
        padding-bottom: 0;
      }
      .elc-basic-tout {
        &__link,
        &-link {
          a {
            @include NeueHaasGrotesk-bold;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            height: 40px;
            overflow: hidden;
            width: 160px;
            @include breakpoint($landscape-up) {
              font-size: 16px;
              height: 44px;
              width: 187px;
            }
          }
        }
      }
    }
  }
  .elc-styled-filters {
    box-shadow: none;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    @include breakpoint($landscape-up) {
      padding: 0 11px;
    }
  }
  .elc-grid-item-tout {
    .elc-product-tout-wrapper {
      position: relative;
      .elc-basic-tout__content-over-media-wrapper,
      .elc-basic-tout-content-below-block {
        padding: 25px 0 0;
        margin-bottom: 20px;
        @include breakpoint($medium-up) {
          margin-bottom: 10px;
        }
        .elc-basic-tout {
          &__header,
          &-header {
            margin-bottom: 10px;
            p {
              @include NeueHaasGrotesk-black;
              font-size: 26px;
              line-height: 1.5;
              margin-bottom: 0;
              text-transform: uppercase;
              @include breakpoint($landscape-up) {
                font-size: 44px;
                line-height: 0.8;
              }
            }
          }
          &__content,
          &-content {
            margin-bottom: 10px;
            padding: 10px 0;
            p {
              @include NeueHaasGrotesk-regular;
              font-size: 15px;
              line-height: 1.5;
              text-transform: initial;
              margin: 0;
              @include breakpoint($landscape-up) {
                font-size: 17px;
              }
            }
          }
        }
      }
      .elc-media-asset {
        img {
          min-height: initial;
        }
      }
    }
  }
}

.breadcrumbs {
  background-color: $color-white;
  max-width: 1310px;
  padding: 30px 20px 10px;
  @include breakpoint($medium-up) {
    margin: 0 auto;
    padding: 30px 25px 10px;
  }
  &__level {
    font-size: 14px;
    text-decoration: none;
    &::before {
      content: '/';
    }
    &:first-child::before,
    &:last-child::before {
      content: '';
    }
    a {
      @include NeueHaasGrotesk-regular;
      border-bottom: 1px solid transparent;
      color: $color-black;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 20px;
      text-decoration: none;
      text-transform: inherit;
      &:hover {
        color: $color-black;
        border-bottom: 1px solid $color-black;
      }
    }
    h1 {
      display: inline-block;
      font-size: 14px;
      line-height: 1.6;
      margin: 0;
      padding: 0;
      text-transform: inherit;
    }
  }
}
